import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import {ReplaySubject, Subject } from 'rxjs';
import { AbstractRestService} from '@gea-mes/cognito';
import * as moment from 'moment';
import { map, find, mergeMap } from 'rxjs/operators';
import { Observable,BehaviorSubject,Subscription } from 'rxjs';

export type WhatsNew = {
  Message: string
  ExpirationDateTime: string
};

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService extends AbstractRestService{

  dateTimeString: string;
  expirationDates: HTMLInputElement;
  message: string;
  expDate : string;
  public wnmessage$: Subject<any> = new ReplaySubject<any>(1);
  private wnSub$:Subscription;
  public appList$: Subject<any> = new ReplaySubject<any>(1);
  private appListSub$:Subscription;
  public app_seleted_values:any;
  
 
  env: any = environment;
  protected baseUrl: string;

  postInit(): void {

  }
  
  onDateSelection(e) {
    this.expDate=(e.start).format('YYYY-MM-DD 23:59:59');
  }

  addWhatsNew()  {

    var resp=true;
    var message = (document.getElementById('whatsNewText') as HTMLInputElement).value;

      let params={
          MessageList:[]     
        }
      let arr=[];
      let tempObj={}
      this.app_seleted_values.forEach(val => {
        tempObj['Message']= message;
        tempObj['Application']=val;
        tempObj['ExpirationDate']=this.expDate;
        arr.push(tempObj);
        tempObj={};
      });
   
        params.MessageList = arr;
          this.post({
            ApiResource: '/whatsnew',
            data: params
          }).subscribe(data => {
              this.fetchWhatsNewMessage();
              resp=true;
            },
              error => {
            console.log("add whatsNew error", error) ;
            resp=false;
          });
        return resp;
  }

  fetchWhatsNewMessage()
  {
    this.wnSub$= this.get({
      ApiResource: '/whatsnew',
      data: {},
      repeatCall: false
    }).pipe(
      map(ret => ret)
    ).subscribe(fav => {
      this.wnmessage$.next(fav);
      this.wnSub$.unsubscribe();
    })

   }

   fetchListOfApplications(){
    this.appListSub$= this.get({
      ApiResource: '/getApplicationsList',
      data: {},
      repeatCall: false
    }).pipe(
      map(ret => ret)
    ).subscribe(fav => {
      this.appList$.next(fav);
      this.appListSub$.unsubscribe();
    })

   }

   whatsNewMessage(): Observable<[]> {
    if(!this.wnSub$){
      this.fetchWhatsNewMessage();
     }
    return this.wnmessage$.asObservable();
  }

  listOfApplications():Observable<[]>
  {
    if(!this.appListSub$){
      this.fetchListOfApplications();
     }
    return this.appList$.asObservable();
  }
  
  getMultiLineMessage(string){
    let updatedMessage=string;
    let multilineMass= updatedMessage.replace(/(\r\n|\n\r|\r|\n)/g, "<br>");
    return multilineMass;
  }

}
