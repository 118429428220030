import { Component, OnInit } from '@angular/core';
import { WhatsNewService } from '../services/whats-new-update.service';
import { HttpClient } from '@angular/common/http';
import { tap, map, filter, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {
updatedMessage:any;
multilineMass:any;
static multilineMass: any;
WNmessages$:Observable<any>;

  constructor(public whatsnewService: WhatsNewService,private http: HttpClient) { }

ngOnInit():void{
 
  this.WNmessages$= this.whatsnewService.whatsNewMessage().pipe(map(
    res =>
      this.whatsnewService.getMultiLineMessage(res['Body']['Messages'][0]['Message'])
    ));
    
   }



}
