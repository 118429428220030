import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GEA_MES_NAVBAR_MENU_SERVICE } from '@gea-mes/navbar';
import { NavItemService, NavItem } from './nav-item.service';
import { BehaviorSubject, Subscription } from "rxjs";
import { appinfo } from './appinfo';
import { ScportalService } from "src/app/services/scportal.service";
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { filter } from 'rxjs/operators';

declare var gtag_setValue; // Declare function defined in index.html

export class ProductModel {
  productId: Product[];
  productType: string;
  constructor(id: Product[], description: string) {
    this.productId = id;
    this.productType = description;
  }
}
export class Product {

  id: number;
  description: string;
  constructor(id: number, key: string) {
    this.id = id;
    this.description = key;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currrentView: any;
  categories = [];
  currnt_view_index: any;
  products = new Map<Product, ProductModel>();
  version: any;
  trackingid: string;
  logon$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public navService: NavItemService,
    private ScportalService: ScportalService,
    private geaMesCognito: GeaMesCognitoAuthService,

    @Inject(GEA_MES_NAVBAR_MENU_SERVICE) private NavItem: BehaviorSubject<NavItem[]>
  ) {

    NavItem.next(navService.navItems);


    var key = new Product(900, "Angular Video");
    var key2 = new Product(900, "Angular Video");
    var product = new ProductModel([key, key2], "Video");


    this.products.set(key, product);
    key = new Product(100, "Angular Book");
    product = new ProductModel([key], "Book");
    this.products.set(key, product);

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.ScportalService.isLoading.next(false);
    });

    this.version = appinfo.version
  }


  ngOnInit(): void {

    gtag_setValue({ "appversion": this.version });
    this.logon$ = this.geaMesCognito.sessionEstablished$.subscribe(out => {
      this.trackingid = this.geaMesCognito.getUserAttribute("trackingid");
      if (this.trackingid != undefined) {
        gtag_setValue({ "sub": this.trackingid });
        if (this.logon$) this.logon$.unsubscribe();
      }
    });

  }

  onActivate(e, apContainer) {
    apContainer.scrollTop = 0;
  }

}
