<div class="layout-row fx-row-wrap fx-layoutgap-16-grid">
    <div class="layout-row card-max-width" *ngFor="let pc of processCaps | async;let index = index">
        <mat-card class="mat-elevation-z4">
            <mat-card-header>
                <mat-card-title class="fx-row-center-center fx-flex-100">
                    {{pc.plantDisplayName}}
                </mat-card-title>
                <mat-card-content style="padding-top: 10px;">
                    <div style="display: flex;">
                        <div class="card-image-spacer-width"></div>
                        <div class="fx-flex-45" style="align-self: center;">
                            <img class="img" src='assets/images/{{pc.name}}.png' default="assets/images/default.png">
                        </div>
                        <div class="fx-flex-55" style="text-align: left; ">
                            <ng-container *ngFor="let plant of sites | async">
                                <ng-container *ngIf="plant.title === currentMenu">
                                    <ng-container *ngFor="let site of plant.apps">
                                        <ng-container *ngIf="capabilityService.siteSupports(pc, site)">
                                            <span class="noWrap">
                                                <div *ngIf="site.alias_used == 'NO'; else elseBlock">
                                                    <div><a class="fav-items-decor" target="_blank"
                                                            (click)="capabilityService.dispatch($event,site,pc)">{{site.display_name}}</a>
                                                        <app-fav-icon-link
                                                            [isFav]="capabilityService.isFav(site.name,pc.name,site.alias_used,'') | async"
                                                            (starChange)="changeFav($event, site, pc,'')">
                                                        </app-fav-icon-link>
                                                    </div>
                                                </div>
                                                <ng-template #elseBlock>
                                                    <div *ngFor="let alias of pc.alias">
                                                        <div> <a class="fav-items-decor" target="_blank"
                                                                (click)="capabilityService.dispatch($event,site,pc,alias)">
                                                                {{site.name}} {{alias.aliasDisplayName}}</a>
                                                            <app-fav-icon-link
                                                                [isFav]="capabilityService.isFav(site.name,pc.name,site.alias_used,alias.aliasName) | async"
                                                                (starChange)="changeFav($event, site, pc,alias.aliasName)">
                                                            </app-fav-icon-link>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </mat-card-content>

            </mat-card-header>
        </mat-card>
    </div>
</div>