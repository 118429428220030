<div style="width: 100%" class="margin-left fx-column">
  <div>
      <h1>Administration</h1>
  </div>
  <div class="admin_page_content" >
    <div class="content_left">
     <div class="margin-top  componentWrapper margin-left" >
     <div class="header" >Authorize a Screen for auto-logon</div>
          <button mat-raised-button color="link" (click)="screenAuth()" class="margin-top" [disabled]="!adminFlag" >Screen Authorization</button>&nbsp;
          <button mat-raised-button color="link" (click)="screenAuthStatus()" class="margin-top" [disabled]="!adminFlag">Screen Authorization Status</button>
  
      </div>
      <br>
      
    </div>

    <div class="content_right">
      <div style="width:100%">
        <div style="float:right">
        <button mat-raised-button color="primary" (click)="openModal('custom-modal-1')" class = "" >Create What’s New Message</button>
        </div>
      </div>

      <br/><br/><br/>
      
      <table class="whatsnew_text_table">
        <thead>
          <th class="whatsnew_table_td1"> Application Name </th>
          <th class="whatsnew_table_td2"> What’s New Message</th>
          <th class="whatsnew_table_td3"> Expiration Date </th>
          <th class="whatsnew_table_td4"> </th>
        </thead>
        <tbody>
          <tr *ngFor="let row of WNmessages$ | async"> 
            <td class="whatsnew_table_td1"> {{ row['Application']}}</td>
            <td class="whatsnew_table_td2"> {{ row['Message']}}</td>
            <td class="whatsnew_table_td3"> {{ row['ExpirationDate'] | date: 'MM/dd/yyyy'}}</td>
            <td class="whatsnew_table_td4"> <button mat-raised-button color="primary" (click)="editModal('custom-modal-1',row)" class = "edit_whats_new_button" >Edit</button> </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="margin-top">
      <jw-modal id="custom-modal-1" >
        <label><b> What's New Text :</b></label>
        <textarea id="whatsNewText" rows="5" cols="167"  >{{selectedAppsWhatsNewUpdate}}</textarea>
        <br/>
        <br/>
        
      <ng-container class="">
      <mat-form-field class="select-form multiselect">
          <mat-select  
          placeholder="Select Application(s)" 
          name="year" 
          class="filter-select"    
          multiple 
          #yearSelect
          [(ngModel)]="appListSelected"         
          >
            <mat-option *ngFor="let app of listOfApps$ | async" value="{{app['ID']}}">
               {{app['Application']}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </ng-container>
        <br/>
        <br/>
        <label > <b>Expiration Date :</b> </label>
        <gea-mes-datepicker
          class="dateRangeBut"
          matTooltip="Select Date"
          (onDateSelect)="onDateSelection($event)"
          singleDate="true"
          showLabel="true"
          showCurrentShift="false"
          defaultRange="Today"
          showRangeLabel="true"
          timeRangeExclude="Today">
        </gea-mes-datepicker>
        <br/>
        <button (click)="cancelModal('custom-modal-1');" mat-raised-button color="primary" class ="left"  >Cancel</button>
        <button (click)="updateModal('custom-modal-1');" mat-raised-button color="primary" class="right"  >Update</button>
      </jw-modal>
    </div>
</div>
