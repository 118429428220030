import { Injectable } from '@angular/core';
import { Observable, ReplaySubject ,Subscription,BehaviorSubject} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, tap ,pluck,mergeMap,find, take} from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { HttpClient } from "@angular/common/http";
import { SitedataService} from '../services/sitedata.service';
import { NavService } from '../services/nav.service';
import { FavoritesServices } from '@gea-mes/navbar';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition} from '@angular/material/legacy-snack-bar'; 
import {GeaNetworkStatusService} from '@gea-mes/navbar';
import { UserService } from './user.service';

export type App = {
  name: string
  abbrev: string
  desc?: string
  href: string
  includes?: string
  l2Href?: string
  alias_used:string
  gea_network:string
  display_name: string
  open_in_same_window?:number
}
export type ProcessArea = {
  title: string,
  desc?: string
  apps: App[]
}
export type Site = {
  id?: string
  type?: string
  name: string
  location?: Location,
  env?:string,
  plantDisplayName?:string
  alias?: Alias[],
  profenv?:string,
  aname?:any,
  open_in_same_window?:number
}
export type Alias ={
  aliasName: string
  aliasDisplayName?: string
}


export type Location = {
  lat: number
  lng: number
};

export type Favorite = {
	Application_ID: string
	Site_ID: string
  plantDisplayName: string
  };

@Injectable({
  providedIn: 'root'
})
export class CapabilityService {
  [x: string]: any;

  private processCaps: ReplaySubject<ProcessArea[]> = new ReplaySubject<ProcessArea[]>();
  private siteCaps: ReplaySubject<Site[]> = new ReplaySubject<Site[]>();
  private env: string = '';
  private imageFavSite:Site;
  siteDataObservable: Observable<any>;
  siteDataSubscription: Subscription;
  visibleIndices = new Set<number>();
  private envDispatch: string = '';
  Data:any;
  alias:any;
  app:any;
  site:any;
  currentMenuValue :any;
  private DataSource:any;
  favorites$:Observable<Favorite[]>;
  imageFavValue:Site;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public spinnerStatusOnWaiting: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public spinnerStatus:boolean=false;
  constructor(private httpClient: HttpClient,
    private sitedataService:SitedataService,
    private navService: NavService,
    public favoriteService: FavoritesServices,
    public userService: UserService,
    public GeaNetworkStatusService : GeaNetworkStatusService,
    private snackBar: MatSnackBar) {
    this.envDispatch= environment.env;
    this.profDispatch = environment.profenv;
    this.sitedataService.getAppConfig().pipe(pluck('Body'),).subscribe(data=>{
      this.processCaps.next(data.config);
      this.siteCaps.next(data.plantList);
    });
  }

  getProcessCapabilities():Observable<ProcessArea[]> {
    return this.processCaps.asObservable();
  }

  getSites(): Observable<Site[]> {
    return this.siteCaps.asObservable();
  }
  

  siteSupports(site: Site, proc: App): boolean {
    return proc && proc.l2Href && proc['sitessupported'] && proc['sitessupported'].includes(site.name);
  }

  changeMessage(data:any) {
    this.DataSource.next(data)
  }
  public getData(){
    return this.DataSource;
  }
  
  dispatch(event: Event, app: App, site: Site, alias?:Alias) {
    this.app=app;
    this.site=site;
    event.stopPropagation();
    let hr = (!!site) ? app.l2Href : app.href;
    if (app.gea_network == 'YES') { 
      this.imageFavValue = {
      name: site.name,
      profenv:this.profDispatch,
      aname:alias.aliasName,
      open_in_same_window:app.open_in_same_window
      }
      this.checkNetworkStatus(hr, app.display_name);
    }
    else{ 
    site.env=this.envDispatch;
    site.open_in_same_window=app.open_in_same_window;
      if(app['l2Href']=="SITE"){
        let direct_url=this.FilterSiteBasedURL(app['siteHref'],site);
        if(site.open_in_same_window==1){
          window.open(direct_url, "_self");
        }else{
          window.open(direct_url, "_blank");
        }
      }else{
        this.navService.navRequest(hr, { site: site })
      }
      
    }
  }


  dispatch_app(event: Event, app: App, site?: Site) {
    this.app=app;
    this.site=site;
    console.log(app)
    event.stopPropagation();
    let hr = (!!site) ? app.l2Href : app.href;
    if( app.alias_used == 'NO' ){
      this.imageFavValue = {
      name: '',
      env:this.envDispatch,
      open_in_same_window:app.open_in_same_window
    }

    if (app.gea_network == 'YES')
      this.checkNetworkStatus(hr, app.display_name);
    else{
      if(!app['href'].includes("{{")){
        let direct_url=app['href'];
        if(app.open_in_same_window==1){
          window.open(direct_url, "_self");
        }else{
          window.open(direct_url, "_blank");
        }
      }else{
        this.navService.navRequest(hr, { site: this.imageFavValue })
      }
    }

      
  }
  }

  dispatchImageFav(event: Event, fav: any) {
    event.stopPropagation();
    let hr = (!!fav.Site_ID) ? fav.l2Href : fav.href;
    if(fav.alias_used == 'NO'){
    this.imageFavValue ={
      name: fav.Site_ID,
      plantDisplayName: fav.plantDisplayName,
      env:this.envDispatch,
      open_in_same_window: +fav.open_in_same_window
     }
     let direct_url="";
     if(fav['Site_ID']=="" && !(fav['href'].includes("{{"))){
      //Application Level Favorite Configured with Direct Link
      direct_url=fav['href'];
      if(fav.open_in_same_window==1){
        window.open(direct_url, "_self");
      }else{
        window.open(direct_url, "_blank");
      }
    }else if(fav['Site_ID']!="" && fav['site_based']=="YES"){
      //Plant Level Favorite Configured with Direct Link
      direct_url=fav['l2Href'];
      if(fav.open_in_same_window==1){
        window.open(direct_url, "_self");
      }else{
        window.open(direct_url, "_blank");
      }
    }else{
      this.navService.navRequest(hr, { site: this.imageFavValue })
    }
      
    }else if(fav.gea_network == 'YES'){
      this.imageFavValue={
        name: fav.Site_ID,
        env:this.envDispatch,
        profenv:this.profDispatch,
        aname:fav.alias,
        open_in_same_window: +fav.open_in_same_window
    }
         this.checkNetworkStatusForImgFvrt(hr, fav.display_name,fav);
    }
  }

 setCurrentMenu(currentMenu : any){
  this.currentMenuValue = 'Manufacturing';
  this.currentMenuValue = currentMenu;
  }


  isFav(appId: string,  siteId: string = "",aliasUsed:string,alias:string): Observable<boolean> {
    let user = this.userService.getMachineName()
    this.favorites$=this.favoriteService.userFavorites(user);
     return this.favorites$.pipe(
       mergeMap(a => a),
       find(a => {
         if(aliasUsed == 'NO'){
           return a.Application_ID === appId && a.Site_ID === siteId
         }
         else {
           return a.Application_ID === appId && a.Site_ID === siteId && a['alias'] === alias
         }
       }),
       map(b => !!b)
     );
   }
   checkNetworkStatus(hr:any, display_name: string){
 
    this.spinnerStatus=true;
    this.spinnerFlag();
     this.GeaNetworkStatusService.getNetworkStatus().pipe(take(1)).subscribe(
       status=>{
        this.spinnerStatus=false;
        this.spinnerFlag();
         if(status == true){
          if(this.app['l2Href']=="SITE"){
            let direct_url=this.FilterSiteBasedURL(this.app['siteHref'],this.site);
            console.log("link_info:",direct_url);
            if(this.site.open_in_same_window==1){
              window.open(direct_url, "_self");
            }else{
              window.open(direct_url, "_blank");
            }
          }else{
            this.navService.navRequest(hr, { site: this.imageFavValue })
          }
            
         }else{
           console.log(hr)
           let snackBarRef = this.snackBar.open('Must be connected to GEA Network or VPN to access ' + display_name, 'OK', {
             duration:0,
             horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,
             panelClass : ['alert-fail'] });
         }})
   }

   checkNetworkStatusForImgFvrt(hr:any, display_name: string, imageFavroite){
    
    this.spinnerStatus=true;
    this.spinnerFlag();
     this.GeaNetworkStatusService.getNetworkStatus().pipe(take(1)).subscribe(
       status=>{
        this.spinnerStatus=false;
        this.spinnerFlag();
         if(status == true){
          let direct_url="";
          if(imageFavroite['Site_ID']=="" && !(imageFavroite['href'].includes("{{"))){
            //Application Level Favorite Configured with Direct Link
            direct_url=imageFavroite['href'];
            if(imageFavroite.site.open_in_same_window==1){
              window.open(direct_url, "_self");
            }else{
              window.open(direct_url, "_blank");
            }
          }else if(imageFavroite['Site_ID']!="" && imageFavroite['site_based']=="YES"){
            //Plant Level Favorite Configured with Direct Link
            direct_url=imageFavroite['l2Href'];
            if(imageFavroite.site.open_in_same_window==1){
              window.open(direct_url, "_self");
            }else{
              window.open(direct_url, "_blank");
            }
          }else{
            this.navService.navRequest(hr, { site: this.imageFavValue })
          }
  
            
         }else{
           console.log(hr)
           let snackBarRef = this.snackBar.open('Must be connected to GEA Network or VPN to access ' + display_name, 'OK', {
             duration:0,
             horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,
             panelClass : ['alert-fail'] });
         }})
   }

   spinnerFlag(): Observable<boolean>{
    this.spinnerStatusOnWaiting.next(this.spinnerStatus);
    return this.spinnerStatusOnWaiting.asObservable();
}

  FilterSiteBasedURL(url_list,site){
    let site_info=url_list.find(url  => url['site'] == site['name']);
    return site_info['site_link']
  }

}


