import { Component, OnInit,ViewChild,ElementRef,ChangeDetectionStrategy } from '@angular/core';
import { FavoritesServices } from '@gea-mes/navbar';
import { Observable } from 'rxjs';
import {FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY} from '@angular/cdk/scrolling';
import{CapabilityService,Site, App} from '../services/capability.service';
import {map} from 'rxjs/operators';
import { UserService } from '../services/user.service';
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 250, 500);
  }
}
export type Favorite = {
	Application_ID: string
	Site_ID: string
  plantDisplayName: string
  };
@Component({
  selector: 'app-favs-imageview',
  templateUrl: './favs-imageview.component.html',
  styleUrls: ['./favs-imageview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy}]
})
export class FavsImageviewComponent implements OnInit {
arr1 = [];  


@ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

public scrollRight(): void {
  this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
}

public scrollLeft(): void {
  this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
}

favorites$:Observable<Favorite[]>;

constructor(public userFavs: FavoritesServices, private capabilityService:CapabilityService, public userService: UserService) { }

  ngOnInit(): void {
    let user = this.userService.getMachineName()
    this.favorites$= this.userFavs.userFavorites(user);	
  }

}
