import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from '@angular/core'
import { ModalService } from './whatsnew-message.service';
import {  WhatsNewService } from 'src/app/services/whats-new-update.service';
import {  Router } from '@angular/router';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition} from '@angular/material/legacy-snack-bar'; 

@Component({
    selector: 'jw-modal',
    templateUrl: 'whatsnew-message.component.html',
    styleUrls: ['whatsnew-message.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    constructor(private modalService: ModalService, 
                private el: ElementRef, 
                private WhatsNewService : WhatsNewService,
                private router: Router,
                private snackBar: MatSnackBar) {
        this.element = el.nativeElement;
        this.router.routeReuseStrategy.shouldReuseRoute = function(){
            return false;
         } 
       
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        //document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'jw-modal') {
                this.cancel();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('jw-modal-open');
    }

    // close modal
    update(): void {
       if(this.WhatsNewService.app_seleted_values.length>0){
            this.WhatsNewService.addWhatsNew();
            if(this.WhatsNewService.addWhatsNew())
            {
                this.snackBar.open('Successfully updated the Whats New Banner text', '', { 
                duration: 5000, 
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass : ['alert-success']
            });
            this.element.style.display = 'none'; 
            this.pageRefresh();
            }
            else{
            this.snackBar.open('Updating the Banner Text Failed', '', { 
                duration: 5000, 
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            }); 
            }
                document.body.classList.remove('jw-modal-open');

        }else{
            this.snackBar.open('Please select at least one application to save.', '', { 
                duration: 2500, 
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
             });
        } 
    }

    // cancel modal
    cancel(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('jw-modal-open');
    }
    pageRefresh() {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
     }
}
