import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ScreenAuthService } from '../../services/screen-auth.service'


@Component({
  selector: 'app-screen-auth',
  templateUrl: './screen-auth.component.html',
  styleUrls: ['./screen-auth.component.scss']
})
export class ScreenAuthComponent implements OnInit {

  ScreenName = new UntypedFormControl('', [Validators.required]);
  Status:string = "";

  constructor(private cookieService: CookieService, private screenAuth: ScreenAuthService) { 
  }

  ngOnInit() {
    this.ScreenName.setValue(this.cookieService.get("MES_AUTH_COMPUTERNAME"));
    if (this.ScreenName.value != "") {
      this.Status = this.ScreenName.value + ": Already Authorized";
    } else {
      this.Status = "Not Authorized";
    }
  }

  authScreen() {
    console.log("Try to authorize screen");
    this.Status = "Authorizing..."
    this.screenAuth.authorizeClient(this.ScreenName.value).subscribe((
      out => {
        console.log("Screen Authorized!!!!");
        console.log(out);
        if (out.ErrorMessage != "") {
          console.error("Failed to authorized screen");
          console.error(out.ErrorMessage);
        } else {
          console.log("Successfully authorized screen");
          this.setCookie("MES_AUTH_COMPUTERNAME",this.ScreenName.value);
          this.setCookie("MES_AUTH_APIKEY",out.Body["api-key"]);

          if (out.Body["keyType"] == "NEW") {
            this.Status = out.Body["ComputerName"] + ":New Screen Authorized";
          } else if (out.Body["keyType"] == "EXISTING") {
            this.Status = out.Body["ComputerName"] + ": Computer Authorized before, reloaded information."
          }

        }
      }
    ));
  }

  deauthScreen() {
    console.log("Try to deauthorize screen");
    this.Status = "DeAuthorizing...";
    this.screenAuth.deauthorizeClient(this.ScreenName.value).subscribe((
      out => {
        console.log("Screen DeAuthorized!!!!");
        console.log(out);
        if (out.ErrorMessage != "") {
          console.error("Failed to deauthorize screen");
          console.error(out.ErrorMessage);
        } else {
          console.log("Successfully deauthorize screen");
          this.deleteCookie("MES_AUTH_COMPUTERNAME");
          this.deleteCookie("MES_AUTH_APIKEY");
          this.ScreenName.setValue("");
          this.Status = out.Body["ComputerName"] + ": " + out.Body["status"];
        }
      }
    ));
  }  

  setCookie(cookieName:string, cookieValue:string) {
    let host = window.location.hostname;
    host = host.slice(host.indexOf(".") + 1); 
    this.cookieService.set( cookieName, cookieValue, 60, '/', host,true,'Strict');
  }

  deleteCookie(cookieName:string) {
    let host = window.location.hostname;
    host = host.slice(host.indexOf(".") + 1); 

    this.cookieService.delete(cookieName, '/', host);
  }


}
