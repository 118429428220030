<div class="fx-column-center-center">
    <div class="screenForm fx-row-space-around-center">
        <div>
            <button mat-button (click)="goToAdmin()">Go to Admin</button>
        </div>
        <div>
            <form (ngSubmit)="onSubmit()" #screenForm="ngForm">
                    <input 
                        type="text"
                        placeholder="Enter a screen name"
                        name="screenName"
                        [(ngModel)] = "screenName">

                <button mat-raised-button type="submit" (click)="onSubmit()" class="btn btn-success subBut">Submit</button>
            </form>  
        </div>
    </div>
    <div class="fx-row-space-around-center">
        <div>
            <mat-card *ngIf="screenInfo" class="screenInfoCard">
                <mat-card-header>
                    <div mat-card-avatar class="example-header-image">
                        <mat-icon *ngIf="screenAuthorized; else elseBlock" class="authorized">check_circle</mat-icon>
                        <ng-template #elseBlock>
                            <mat-icon class="notAuthorized">clear</mat-icon>
                        </ng-template>
                    </div>
                    <mat-card-title>{{screenInfo['SCREEN-NAME']}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-list role="list">
                        <mat-list-item role="listitem">API Key ID: {{screenInfo['APIKEY-ID']}}</mat-list-item>
                        <mat-list-item role="listitem">Create Date: {{screenInfo['CREATE-DATE']}}</mat-list-item>
                        <mat-list-item role="listitem">Last Key Rotation: {{screenInfo['LAST-KEY-ROTATION']}}</mat-list-item>
                    </mat-list>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button *ngIf="deauthorizeButton" (click)="deauthorized()">Deauthorize Screen</button>
                </mat-card-actions>
            </mat-card>
        </div>    
    </div>

</div>