import { Component, OnInit,Input ,Output,EventEmitter,ViewChild} from '@angular/core';
import { Observable } from 'rxjs';
import{CapabilityService,Site, App} from '../services/capability.service';
import { FavoritesServices } from '@gea-mes/navbar';

import { StarEvent } from '../fav-icon-link/fav-icon-link.component'
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition} from '@angular/material/legacy-snack-bar'; 
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-apps-mat-card',
  templateUrl: './apps-mat-card.component.html',
  styleUrls: ['./apps-mat-card.component.scss']
})
export class AppsMatCardComponent implements OnInit {
  @Input() processCaps: Observable<any>;
  @Input() sites: Observable<any>;
  @Output() myEvent = new EventEmitter();
  currentMenu : any;
  constructor(private capabilityService:CapabilityService,
              public userFavs: FavoritesServices,
              private snackBar: MatSnackBar,
              private userService: UserService) { }
  
  ngOnInit(): void {
    this.currentMenu = this.capabilityService.currentMenuValue;

    if(this.currentMenu == undefined){
      this.currentMenu = 'Manufacturing';
   
    }
  }
  changeFav(event: StarEvent, app: App, site: Site, aliasName:string) {
    let siteId = site ? site.name : null;
    let user = this.getMachineName()
   event.starred ? this.userFavs.addUserFavorite(app.name, siteId, aliasName, user) : this.userFavs.deleteUserFavorite(app.name, siteId,aliasName, user)
  }

  getMachineName() {
    return this.userService.getMachineName()
  }

}
