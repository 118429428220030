<div class="screen-auth">
    <h1>Screen Authorization</h1>
    <p>Authorizing a screen will allow that screen to access basic applications without regular logons.</p>
    <p><b>Status: </b>{{Status}}</p>
    <mat-form-field>
        <mat-label>Screen Name</mat-label>
        <input matInput placeholder="Screen Name" [formControl]="ScreenName">
    </mat-form-field>
    <div>
        <button mat-raised-button color="link" (click)="authScreen()">Authorize Screen</button>&nbsp;
        <button mat-raised-button color="link" (click)="deauthScreen()">DeAuthorize Screen</button>
    </div>
</div>