import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  MACHINE_NAME_PROP:string = 'MES_AUTH_COMPUTERNAME';

  constructor(private cookieService: CookieService) { }

  getMachineName() {
    let machineName = this.cookieService.get(this.MACHINE_NAME_PROP)
    return !machineName || machineName == "" ? "" : machineName
  }
}
