<div class="fx-row-wrap">
    <div class="fav-container fx-row-center-center fx-flex-100 fx-flexorder-2"
        *ngIf="(favorites$| async)?.length!=0" style="background-color: white;">
        <div class="fx-flex-95">
            <app-favs-imageview></app-favs-imageview>
        </div>
    </div>
    
    <div class="fx-flex-95 fx-flexorder-4 fx-flex-95 fx-flexorder-4 toogle-div" *ngIf="this.router.url == '/manufacturing'">
        <div class="toogle-div fx-row-end-end">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" >
                <mat-button-toggle checked value="bold" class="padding_5"  (click)="onPlant($event)"
                    [ngClass]="onPlan ? 'section-button-checked' : null">BY PLANT
                </mat-button-toggle>
                <mat-button-toggle value="italic" class="padding_5"  (click)="onApplication($event)"
                    [ngClass]="onApp ? 'section-button-checked' : null">BY APPLICATION
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="fx-flex-100 fx-row-center-center fx-flexorder-5" style="background-color: #F2F2F2;">
        <div class="fx-row-center-center fx-flex-90">
            <div class="viewHeading">
                <span class="viewHeadingText">
                    <b *ngIf="onApp">{{currrentView.displayName}}</b>
                    <b *ngIf="onPlan">{{currrentView.displayName}}</b>
                </span>
                <hr class="hrline">
            </div>
        </div>
    </div>
    <div class="fx-row-center-center fx-flexorder-6 fx-flex-100" *ngIf="this.router.url == '/manufacturing'">
        <div class="fx-flex-75">
            <ng-container *ngIf="onApp; else byplant">
                <app-apps-mat-card [processCaps]="processCaps$" [sites]="sites$" [byAPP]="onApp">
                </app-apps-mat-card>
            </ng-container>
            <ng-template #byplant>
                <app-site-mat-card [processCaps]="sites$" [sites]="processCaps$" [byAPP]="onApp">
                </app-site-mat-card>
            </ng-template>
        </div>
    </div>
    <div class="fx-row-center-center fx-flexorder-6 fx-flex-100" *ngIf="this.router.url == '/distribution'">
        <div class="fx-flex-75">
            <ng-container>
                <app-apps-mat-card [processCaps]="processCaps$" [sites]="sites$" [byAPP]="onApp">
                </app-apps-mat-card>
            </ng-container>
        </div>
    </div>

    <div class="fx-row-center-center fx-flexorder-6 fx-flex-100" *ngIf="this.router.url == '/reports'">
        <div class="fx-flex-75">
            <ng-container>
                <app-apps-mat-card [processCaps]="processCaps$" [sites]="sites$" [byAPP]="onApp">
                </app-apps-mat-card>
            </ng-container>
        </div>
    </div>
    
    <mat-spinner color="primary" *ngIf="capService.spinnerFlag() | async"></mat-spinner>
</div>