import { Component, OnInit ,Input} from '@angular/core';
import{CapabilityService,Site, App} from '../services/capability.service';
import { Observable } from 'rxjs';
import { FavoritesServices }from '@gea-mes/navbar';
import { StarEvent } from '../fav-icon-link/fav-icon-link.component'
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-site-mat-card',
  templateUrl: './site-mat-card.component.html',
  styleUrls: ['./site-mat-card.component.scss']
})
export class SiteMatCardComponent implements OnInit {
  @Input() processCaps: Observable<any>;
  @Input() sites: Observable<any>;
  currentMenu : any;
  constructor(private capabilityService:CapabilityService, public userFavs: FavoritesServices, private userService: UserService) { }
  ngOnInit(): void {

    this.currentMenu = this.capabilityService.currentMenuValue;
    if(this.currentMenu == undefined){
      this.currentMenu = 'Manufacturing';
    }
  }
  
  changeFav(event: StarEvent, app: App, site: Site,aliasName:string) {
    let siteId = site ? site.name : null;
    let user = this.getMachineName()
    event.starred ? this.userFavs.addUserFavorite(app.name, siteId,aliasName, user) : this.userFavs.deleteUserFavorite(app.name, siteId,aliasName, user)
  }

  getMachineName() {
    return this.userService.getMachineName()
  }
}