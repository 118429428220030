import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
  GeaMesLoginComponent,
  GeaMesCognitoAuthGuard,
  GeaMesCognitoModule,
  COGNITO_CONFIG,
  GeaMesAuthInterceptor,
  APPLICATION_NAME, APIGATEWAY_CONFIG, GeaMesCognitoAuthService,ALLOW_SCREENAUTH
} from '@gea-mes/cognito';
import { environment } from 'src/environments/environment';
import { appinfo } from './appinfo';
import { GEA_MES_NAVBAR_COGNITO_CONFIG, GEA_MES_NAVBAR_APPLICATION_NAME, 
  GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, GEA_MES_NAVBAR_MENU_SERVICE, GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT,GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT} from '@gea-mes/navbar';

import { NavItem } from './nav-item.service';
import { BehaviorSubject } from "rxjs";
import { AdminHomeComponent } from './admin/components/admin-home/admin-home.component';
import { ScreenAuthComponent } from './admin/components/screen-auth/screen-auth.component';
import { ScreenAuthStatusComponent } from './admin/components/screen-auth-status/screen-auth-status.component';
import { LogOutPageComponent} from './log-out-page/log-out-page.component';
import { HomeComponent} from './home/home.component';

const routes: Routes = [

  { path: 'login', component: GeaMesLoginComponent },
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
    children: [

      { path: '', redirectTo: '/manufacturing', pathMatch: 'full' },
      { path: 'manufacturing', component: HomeComponent, data:{ routeStr: 'manufacturing' }},
      { path: 'distribution', component: HomeComponent, data:{ routeStr: 'distribution' }},
      { path: 'reports', component: HomeComponent, data:{ routeStr: 'reports' }},
      {
        path: 'admin',
        children: [
          { path: '', component: AdminHomeComponent },
          { path: 'screenauth', component: ScreenAuthComponent },
          { path: 'screenauthstatus', component: ScreenAuthStatusComponent },
        ]
      },
    ],
  },

  { path: 'logout', component: LogOutPageComponent},
  { path: '**', redirectTo: '/manufacturing' },

];

@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(routes, {})],
  providers: [
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },

    { provide: GEA_MES_NAVBAR_APPLICATION_NAME, useValue: "Supply Chain Portal"},
    { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, useClass: GeaMesCognitoAuthService },
    { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
    { provide:GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT, useValue:"SCPortal"},


    { provide: GEA_ENVIROMENT, useValue: environment },
    { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
