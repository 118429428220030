
import { Injectable,Inject} from '@angular/core';
import { environment } from './../environments/environment';


export interface NavItem {
  
  displayName: string;
  disabled?: boolean;
  show:String;
  iconName: string;
  route?: string;
  children?: NavItem[];
  gea_network:string;
  onClick?(): void;
  href?:string;
}



@Injectable()

export class NavItemService {
  /* navItems are the list of menu items those are displayed on the common nav bar */
  navItems: NavItem[] = [

    {
        "displayName": "Logout",
        "disabled": false,
        "show":"mobile",
        "iconName": "",
        "gea_network":"No",
        "children": [
            {
              "displayName": 'Logout',
              "iconName": '',
              "route": 'logout',
              "show":"mobile",
              "gea_network":"No",
            }]
       
    },     
    {
        "displayName": "Manufacturing",
        "disabled": false,
        "show":"both",
        "iconName": "",
        "children": [],
        "route": "manufacturing",
        "gea_network":"No",
      
        
    },
    {
        "displayName": "Distribution",
        "show":"both",
        "disabled": false,
        "iconName": "",
        "children": [],
        "route": "distribution",
        "gea_network":"No",
    },
    {
        "displayName": "Training",
        "disabled": false,
        "show":"both",
        "iconName": "",
        "children": [],
        "route": "",
        "gea_network":"No",
        "href":"https://geappliances.sharepoint.com/:f:/s/ManufacturingIT/Emjgww4Rn0dIt_5UyuHLCqEBk7752IZzsNRvc1fkN99riQ?e=aUZrlg"
       
    },
    // {
    //     "displayName": "Configuration",
    //     "disabled": false,
    //     "show":"both",
    //     "iconName": "",
    //     "children": [],
    //     "route": "configuration",
    //     "gea_network":"No",
    // },
    {
        "displayName": "Our Team",
        "disabled": false,
        "show":"both",
        "iconName": "",
        "children": [],
        "route": "",
        "gea_network":"No",
        "href": "https://geappliances.sharepoint.com/:w:/r/sites/ManufacturingIT/_layouts/15/Doc.aspx?sourcedoc=%7B2ac56343-a8a8-4745-b8cd-6740548fb670%7D&action=embedview&cid=95661566-f168-4207-b69c-c6480fed5eb6"
    },
    {
        "displayName": "What's New",
        "disabled": false,
        "show":"both",
        "iconName": "",
        "children": [],
        "route": "",
        "gea_network":"No",
        "href": "https://geappliances.sharepoint.com/:w:/r/sites/ManufacturingIT/_layouts/15/Doc.aspx?sourcedoc=%7B48F460E1-CD09-4197-8701-A9ED6DA844D8%7D&action=embedview&cid=9c97bdb5-009f-4a79-82f7-55dc5e23b4f7"
     }, 
     {
      "displayName": "Reports",
      "disabled": false,
      "show":"both",
      "iconName": "",
      "children": [],
      "route": "reports",
      "gea_network":"No"
   }, 
    //, {
    //     "displayName": "Setting",
    //     "disabled": false,
    //     "show":"mobile",
    //     "iconName": "settings",
    //     "children": [],
    //     "route": "setting",
    //     "gea_network":"No",
    // } ,
    // {
    //     "displayName": "Help",
    //     "disabled": false,
    //     "show":"mobile",
    //     "iconName": "help",
    //     "children": [],
    //     "route": "help",
    //     "gea_network":"No",
    // } 
    ];
    }
