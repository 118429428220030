<div *ngFor="let pc of processCaps | async">
    <div class="fx-row-wrap fx-layoutgap-16-grid" *ngIf="pc.title === currentMenu">
        <div class="layout-row card-max-width" *ngFor="let a of pc.apps " style="padding: 0px 16px 16px 0px">
            <mat-card class="mat-elevation-z4">
                <mat-card-header style="display: flex">
                    <div class="fx-row-wrap-end-end fx-flex-100">
                        <mat-card-title target="_blank" [ngClass]="{'dcursor': a.href === ''}"
                            (click)="capabilityService.dispatch_app($event,a)">
                            <mat-icon [style.color]="a.icon_color" class="contentCard-app-Icon">{{a.iconPath}}
                            </mat-icon>
                            <span style="cursor: pointer;">{{a.display_name}}</span>
                            <app-fav-icon-link class="matHeaderFavIcon" [ngClass]="{'hideIcon': a.href==''}"
                            [isFav]="capabilityService.isFav(a.name,'','','') | async"
                            (starChange)="changeFav($event, a, null,'')"></app-fav-icon-link>
                        </mat-card-title>
                    </div>
                </mat-card-header>
                <mat-card-content>
                    <div class="fx-row-wrap fx-flex-100">
                        <div class="card-spacer-width"></div>
                        <p class="card-desc-width" style="height: fit-content; ">{{a.desc}}</p>
                        <div class="card-desc-spacer-width"></div>
                        <div class="card-site-width" style="align-self: flex-start; text-align: start; ">
                            <ng-container *ngFor="let site of sites | async">
                                <ng-container *ngIf="capabilityService.siteSupports(site, a)">
                                    <span class="noWrap">
                                        <div *ngIf="a.alias_used == 'NO' ; else elseBlock">
                                            <a class="fav-items-decor" target="_blank"
                                                (click)="capabilityService.dispatch($event,a,site)">{{site.plantDisplayName}}</a>
                                            <app-fav-icon-link
                                                [isFav]="capabilityService.isFav(a.name,site.name,a.alias_used,'') | async"
                                                (starChange)="changeFav($event, a, site,'')">
                                            </app-fav-icon-link>
                                        </div>
                                        <ng-template #elseBlock>
                                            <div *ngFor="let alias of site.alias">
                                                <div>
                                                    <a class="fav-items-decor"
                                                        (click)="capabilityService.dispatch($event,a,site,alias)"
                                                        target="_blank">
                                                        {{site.name}} {{alias.aliasDisplayName}}
                                                    </a>
                                                    <app-fav-icon-link
                                                        [isFav]="capabilityService.isFav(a.name,site.name,a.alias_used,alias.aliasName) | async"
                                                        (starChange)="changeFav($event, a, site, alias.aliasName)">
                                                    </app-fav-icon-link>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </span>
                                </ng-container>
                            </ng-container>  
                        </div>                                        
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>