import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {  WhatsNewService } from 'src/app/services/whats-new-update.service';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators'
import { ModalService } from '../whatsnew-message';
import * as moment from 'moment';
import { tap, map, filter, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GeaMesDatepickerService, DatePickObj } from '@gea-mes/datepicker';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  adminSub$: Subscription;
  adminFlag: boolean;
  updatedMessage$:Observable<any>;
  selectedAppsWhatsNewUpdate:string;
  multilineMass$:any;
  date : any;
  startdate$ :Observable<any>;
  WNmessages$:Observable<any>;
  listOfApps$:Observable<any>;
  appListSelected:any;
  constructor(private router: Router,
    private geaMesCognito: GeaMesCognitoAuthService,
    public WhatsNewService : WhatsNewService,
    private modalService : ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePicker: GeaMesDatepickerService

    ) { }

  ngOnInit() {
    
     // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => {
    this.adminFlag = out;

    if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

    
    this.WNmessages$=this.WhatsNewService.whatsNewMessage().pipe(map(
      res => res['Body']['Messages']
      ));

      this.listOfApps$=this.WhatsNewService.listOfApplications().pipe(map(
        res => res['Body']['ApplicationList']
      ));
  

    this.updatedMessage$=this.WhatsNewService.whatsNewMessage().pipe(map(
      res =>res['Body']['Messages'][0]['Message']
      ));

    this.startdate$=this.WhatsNewService.whatsNewMessage().pipe(map(
      res=> this.formatDate(res['Body']['Messages'][0]['ExpirationDate'])
    )); 

  
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  formatDate(dbDate){
    return moment(dbDate).format('MM/DD/YYYY');
  }
  screenAuth() {
    this.router.navigate(["/admin/screenauth"]);
  }

  screenAuthStatus(){
    this.router.navigate(['/admin/screenauthstatus'])
  }
  openModal(id: string) {
    this.appListSelected=[];
    this.selectedAppsWhatsNewUpdate="";
    this.modalService.open(id);
    this.WhatsNewService.expDate=moment().format('YYYY-MM-DD 23:59:59');
    let current_expiration_date=moment().format('MM/DD/YYYY');
    let myDate:DatePickObj = {
      label: current_expiration_date,
      currentShift: false,
      start: dayjs(current_expiration_date),
      end: dayjs(current_expiration_date)
    }
    this.datePicker.updateDatePickObjGroup(myDate, 0)
  }

  editModal(id: string,wntext) {
    this.selectedAppsWhatsNewUpdate=wntext['Message'];
    this.appListSelected=[wntext['ID']];
    this.WhatsNewService.expDate=moment(wntext['ExpirationDate']).format('YYYY-MM-DD 23:59:59')
    this.modalService.open(id);
    let current_expiration_date=moment(wntext['ExpirationDate']).format('MM/DD/YYYY');
    console.log("current_expiration_date:",current_expiration_date)
    let myDate:DatePickObj = {
      label: current_expiration_date,
      currentShift: false,
      start: dayjs(current_expiration_date),
      end: dayjs(current_expiration_date)
    }
    this.datePicker.updateDatePickObjGroup(myDate, 0)
  }

  updateModal(id: string) {
    this.WhatsNewService.app_seleted_values=this.appListSelected;
    this.modalService.update(id);
  }
  cancelModal(id: string) {
    this.modalService.cancel(id);
  }
  onDateSelection(e) {
      this.WhatsNewService.onDateSelection(e);
      
  }
  
}
