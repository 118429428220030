import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {CapabilityService} from '../services/capability.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavItemService} from '../nav-item.service';
import { Subject  } from 'rxjs';
import { WhatsNewService} from '../services/whats-new-update.service';
import { FavoritesServices } from '@gea-mes/navbar';
import { map  } from 'rxjs/operators';
import { UserService } from '../services/user.service';


export type Favorite = {
	Application_ID: string
	Site_ID: string
  };
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})



export class HomeComponent implements OnInit {
  isMobile$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  navEvent$:Subject<string> = new Subject<string>();
  onApp:boolean=false;
  onPlan:boolean=true;
  currnt_view_index:any; 
  processCaps$: Observable<any>;
  sites$: Observable<any>;
  updatedMessage:any;
 
  byPlant:Observable<any>;
  byApp:Observable<any>;

  data:any;
  currrentView:any;
  categories= [];


  myCarousel;
	carouselWidth = 200;
  carouselHeight = 120;
  paddingString:string;
  
  
  favorites$:Observable<Favorite[]>;
  //dynamicPadding:string;



  
 



  constructor( public capService: CapabilityService,
    public router: Router,
    private route: ActivatedRoute,
    public navService:NavItemService,
    public userFavs: FavoritesServices,
    public whatsnewService:WhatsNewService,
    public userService: UserService,
    private breakpointObserver: BreakpointObserver,
    ) { }

  ngOnInit(): void {


    //this.dynamicPadding="defualtClass"

    let valueChange;
    this.processCaps$ = this.capService.getProcessCapabilities();
    this.sites$ = this.capService.getSites();
    let user = this.userService.getMachineName()
    this.favorites$= this.userFavs.userFavorites(user);
   

    /*  Fecting all the menu items and dynamically creating views excluding Logout */
    let navItems= this.navService.navItems;
    for (const [index, value] of navItems.entries()){
      if(navItems[index]['route'] && navItems[index]['displayName'].toLocaleLowerCase()!="logout"
         && navItems[index]['displayName'].toLocaleLowerCase()!="setting"
         && navItems[index]['displayName'].toLocaleLowerCase()!="help"){
        this.categories.push(navItems[index]);
      }
    }
     /* Setting the view to be first item in list by deafault */
    this.currrentView=this.categories[0];

    /* Logic to update view if user navigated from nav bar menu header */
    this.route.data.subscribe(routeData => {
      if(routeData){
        let routeString=routeData.routeStr;
        this.categories.forEach( (myObject, index) => {
          if(myObject.route==routeString){
            this.currrentView=this.categories[index];
            this.capService.setCurrentMenu(this.currrentView.displayName);
          }
        });
       }
    });

    valueChange = this.categories.findIndex(p => p.route == this.route.snapshot.data['routeStr']);      
    
    }

  ngAfterViewInit() {
    
  }

  // dynamicCSS(isFvtVisible,isWhatNewVisible){
  //   if(isFvtVisible && isWhatNewVisible ){
  //     this.dynamicPadding="fvrtsWatsNew";
  //   }
  //   else if(!isFvtVisible && ! isWhatNewVisible ){
  //     this.dynamicPadding="noFvrtsNoWatsNew";
  //   }else if(isFvtVisible && ! isWhatNewVisible){
  //     this.dynamicPadding="onlyFavrts";
  //   }else if(!isFvtVisible && isWhatNewVisible){
  //     this.dynamicPadding="onlyWhatsNew";
  //   }
  // }



  onApplication(_$event: any) {
    if (true) {
      this.onApp=true;
      this.onPlan=false;      
    } 
  }
  
  
  onPlant(_$event: any){
    if(true){
      this.onApp=false;
      this.onPlan=true;      
    }
  }



requestFullscreen() {
  document.documentElement.requestFullscreen();
}

handleCarouselEvents(event) {
if (event.type === "click") {
}
}



next() {
this.myCarousel.next();
}

prev() {
this.myCarousel.prev();
}

resize() {

  if (this.carouselWidth === 320) {
  this.carouselWidth = 480;
  this.carouselHeight = 320;
} else {
  this.carouselWidth = 60;
  this.carouselHeight = 60;
}
}

select(index) {
this.myCarousel.select(index);
}

whatsNewMessage(res:any){
  if(res['Body']['Messages'].length > 0){
     return res['Body']['Messages'][0]['Message'];
  }
}


}
