import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { GeaMesNavbarModule}from '@gea-mes/navbar';
import { NavItemService} from './nav-item.service'
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { WcConfirmDialog } from './admin/components/wc-confirm/wc-confirm.dialog'
import { UserComponent } from './user/user.component';
import { AdminHomeComponent } from './admin/components/admin-home/admin-home.component';
import { ScreenAuthComponent } from './admin/components/screen-auth/screen-auth.component';
import { ScreenAuthStatusComponent } from './admin/components/screen-auth-status/screen-auth-status.component';
import { MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import { CapabilityService } from './services/capability.service';

import { AppsMatCardComponent } from './apps-mat-card/apps-mat-card.component';
import { SiteMatCardComponent } from './site-mat-card/site-mat-card.component';
import { ImagePreloadDirective } from './directive/image-preload-directive.directive';

import { FavIconLinkComponent } from './fav-icon-link/fav-icon-link.component';
import { FavsImageviewComponent } from './favs-imageview/favs-imageview.component';
import {MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/legacy-snack-bar';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';
import { WhatsNewModule } from './admin/components/whatsnew-message';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

const MatComponents = [
  MatCardModule
];
const MatButtons = [MatButtonToggleModule,MatButtonModule];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WcConfirmDialog,
    UserComponent,
    AdminHomeComponent,
    ScreenAuthComponent,
    ScreenAuthStatusComponent,
    AppsMatCardComponent,
    SiteMatCardComponent,
    ImagePreloadDirective,
    FavIconLinkComponent,
    FavsImageviewComponent,
    WhatsNewComponent,
    LogOutPageComponent

  ],
  imports: [
    GeaMesNavbarModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatComponents,
    MatButtons,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
	  GeaMesDatepickerModule,
    WhatsNewModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSelectModule

  ],
  exports:[
    MatComponents,
    MatButtons,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,



  ],

  providers: [NavItemService,CapabilityService,MatSnackBar,{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
