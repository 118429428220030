import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SitedataService  extends  AbstractRestService{
  
  
  postInit(): void {
    console.log("Post INIT!");
  }
  


  getSiteData():Observable<GenericLambdaReturn<any>> 
  {
    return this.get({
      ApiResource: "/sitedata",  data: '',repeatCall: false
     })    
  }

  getAppConfig():Observable<GenericLambdaReturn<any>> 
  {
    return this.get({
      ApiResource: "/appConfig",  data: '',repeatCall: false
     })    
  }
}
