
 

 <div class="container">

	<div class="fx-row-center-center">
	
	<div style="float: left" *ngIf="(favorites$| async)?.length>7">
		<mat-icon (click)="scrollLeft()">keyboard_arrow_left</mat-icon>
	</div>


	<div #widgetsContent class="middle row" >
		<ng-container *ngFor="let pc of favorites$ | async">
			<div  class="info-widget"   style="word-wrap: normal" (click)="capabilityService.dispatchImageFav($event,pc)">
			
				<!-- <img src="/assets/images/1.jfif"  class="img"> -->
				<mat-icon class="fvrtImgIcon" [style.color]="pc.icon_color">{{pc.Icon}}</mat-icon>
				
				<div style="width: 100%;text-align: center;" class="fx-row-center-center">
				<h3  class="h3">{{pc.Site_ID ? pc.Site_ID +' ':''}}{{pc.alias_name ? pc.alias_name +' ':''}}
					{{pc.display_name}}</h3>
			  </div>
			</div>
	
	
	</ng-container>
</div>
		
<div style="float: right" *ngIf="(favorites$| async)?.length>7">
	<mat-icon (click)="scrollRight()">keyboard_arrow_right</mat-icon>
</div>
</div>
</div>
