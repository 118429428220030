import { Component, OnInit } from '@angular/core';
import { ScreenAuthService } from '../../services/screen-auth.service';
import { pluck } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface screenInfoReturn {
  Item: screenInfo
  ResponseMetadata: Object
}

export interface screenInfo {
  'APIKEY-ID': string
  'CREATE-DATE': string
  'LAST-KEY-ROTATION': string
  'SCREEN-NAME': string
}

@Component({
  selector: 'app-screen-auth-status',
  templateUrl: './screen-auth-status.component.html',
  styleUrls: ['./screen-auth-status.component.scss']
})


export class ScreenAuthStatusComponent implements OnInit {

  screenName: string;
  screenInfo: screenInfo;
  screenAuthorized: boolean;
  deauthorizeButton: boolean;

  constructor(private screenAuthService: ScreenAuthService,
    private router: Router) {
      this.screenName = ''
      this.screenAuthorized = true
      this.deauthorizeButton = true
   }

  ngOnInit() {
  }

  onSubmit(){
    this.screenAuthService.getScreenStatus(this.screenName).pipe(
      pluck('Body')
    ).subscribe((screenInfo: screenInfoReturn) =>{
      if(screenInfo.Item){
        this.screenAuthorized = true
        this.deauthorizeButton = true
        this.screenInfo = screenInfo.Item
      } else {
        this.screenAuthorized = false
        this.deauthorizeButton = false
        this.screenInfo = {
          'APIKEY-ID':  'No Date for this Screen',
          'CREATE-DATE': 'No Date for this Screen',
          'LAST-KEY-ROTATION': 'No Date for this Screen',
          'SCREEN-NAME': 'Screen is Not Authorized' 
        }
      }
    })
  }

  deauthorized(){
    this.screenAuthService.deauthorizeClient(this.screenName).pipe(
      pluck('Body')
    ).subscribe(
      out => {
        this.screenAuthorized = true
        this.deauthorizeButton = false
        this.screenInfo = {
          'APIKEY-ID':  'Screen was Deauthorized',
          'CREATE-DATE': '',
          'LAST-KEY-ROTATION': '',
          'SCREEN-NAME': this.screenName + ' Deauthorized'
        }
      },
      error => {
        this.screenAuthorized = false
        this.deauthorizeButton = false
        this.screenInfo = {
          'APIKEY-ID':  error,
          'CREATE-DATE': '',
          'LAST-KEY-ROTATION': '',
          'SCREEN-NAME': 'Error Deauthorizing ' + this.screenName
        }
      })
  }

  goToAdmin(){
    console.log("nav")
    this.router.navigate(['/admin'])
  }


}
