import { Component, OnInit , ViewEncapsulation, ChangeDetectionStrategy,
  Input, Output, EventEmitter} from '@angular/core';
  import { Subject, BehaviorSubject } from 'rxjs';
  import { debounceTime, distinctUntilKeyChanged } from 'rxjs/operators';
  export type StarEvent = {
    starred: boolean;
  }

@Component({
  selector: 'app-fav-icon-link',
  templateUrl: './fav-icon-link.component.html',
  styleUrls: ['./fav-icon-link.component.scss'],

  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavIconLinkComponent implements OnInit {
  @Input() showStar:boolean=true;
  @Input() name:string;
  public hovered:boolean;
  @Input() baseIcon:string;
  @Output() starChange:EventEmitter<StarEvent> = new EventEmitter<StarEvent>();
  @Input() isFav:boolean;
  private starEvent:Subject<StarEvent>;
  constructor() { }

  ngOnInit(): void {

    this.starEvent=new Subject<StarEvent>();
    this.starEvent.pipe(
      debounceTime(1000),
      distinctUntilKeyChanged('starred')
    ).subscribe(e=> {
      this.starChange.emit(e);
    });
  }


  get favorite() {
    return this.isFav ? "star" : "star_border";
  }


  toggleFav(event) {
    event.stopPropagation();
    this.isFav=!this.isFav;
    this.starEvent.next({starred: this.isFav});
  }

  get icon():string {
    return this.hovered ? "launch": this.baseIcon;
  }
}
