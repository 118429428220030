import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private navEventSource = new Subject<any>();

  navEvent$ = this.navEventSource.asObservable();

  constructor() { }

  navRequest(req:string, params?:any) {
    this.navEventSource.next(
      req.replace(
        /\{\{(.+?)\}\}/g,
        (_,p1)=>this.getDescendantProp(params, p1)
      )
    );
  

    var url=req.replace(
      /\{\{(.+?)\}\}/g,
      (_,p1)=>this.getDescendantProp(params, p1)
    );
    
    if(typeof params != "undefined" && typeof params.site != "undefined" &&
      params.site.open_in_same_window == 1){
      window.open(url, "_self");
    }else{
      window.open(url, "_blank");
    } 
   
  }

  private getDescendantProp(obj:any, desc:string):string {
    var arr = desc.split('.');
    while (arr.length) {
      obj = obj[arr.shift()];
    }
    return obj;
  }


}
