import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService} from '@gea-mes/cognito';
import { GenericLambdaReturn } from '../../models/genericLambda';

@Injectable({
  providedIn: 'root'
})
export class ScreenAuthService extends AbstractRestService {

  postInit(): void {}  

  authorizeClient(computerName:string ):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ComputerName = computerName;
    return this.put({
      ApiStage: 'auth',
      ApiResource: '/ClientAuthorization',
      data: params,
      repeatCall: false
    });
  }

  deauthorizeClient(computerName:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ComputerName = computerName;
    return this.delete({
      ApiStage: 'auth',
      ApiResource: '/ClientAuthorization',
      data: params,
      repeatCall: false
    });
  }

  getScreenStatus(computerName: string): Observable<GenericLambdaReturn<any>> {
    let params: any = {}

    params.ComputerName = computerName
    return this.get({
      ApiStage: 'auth',
      ApiResource: '/ClientAuthorization',
      data: params,
      repeatCall: false
    });
  }


  
  

}
